import React from "react";
import DataComponent from "./../../Shared/index";

function PaymentStatuses() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/Payment-statuses.md"
      withBoth={true}
      linkPrevious="insights-categories"
      textPrevious="Insights categories"
      linkNext="merchants"
      textNext="Merchants"
    />
  );
}

export default PaymentStatuses;
