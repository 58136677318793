import React from "react";
import DataComponent from "./../../Shared/index";

function Authorization() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/flows-authorization.md"
      withBoth={true}
      linkPrevious="webhooks"
      textPrevious="Webhooks"
      linkNext="account-verification"
      textNext="Account verification"
    />
  );
}

export default Authorization;
