import React from "react";

const SidebarToggle = () => {
  const toggleSidebar = () => {
    document.getElementById("sidebar").classList.toggle("collapsed");
    document
      .getElementById("sidebar-toggle")
      .classList.toggle("toggle-collapsed");
  };

  return (
    <div id="sidebar-toggle">
      <button className="toggle" onClick={toggleSidebar}>
        <div>
          <svg
            width="1.5em"
            height="1.5em"
            viewBox="0 0 16 16"
            className="sidebar-toggle-icon"
            fill="#044f68"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </div>
      </button>
    </div>
  );
};
export default SidebarToggle;
