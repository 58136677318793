import React from "react";
import DataComponent from "./../../Shared/index";

function SupportedProviders() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/Supported-providers.md"
      withBoth={true}
      linkPrevious="sandbox"
      textPrevious="Sandbox"
      linkNext="glossary"
      textNext="Glossary"
    />
  );
}

export default SupportedProviders;
