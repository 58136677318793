import React from "react";
import DataComponent from "./../../Shared/index";

function Merchants() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/Merchants.md"
      withBoth={true}
      linkPrevious="payment-statuses"
      textPrevious="Payment statuses"
      linkNext="split-payments"
      textNext="Split payments"
    />
  );
}

export default Merchants;
