import React from "react";
import DataComponent from "./../../Shared/index";

function Payouts() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/Payouts.md"
      withBoth={true}
      linkPrevious="signing-basket"
      textPrevious="Signing basket"
      linkNext="use-cases"
      textNext="Use cases"
    />
  );
}

export default Payouts;
