import React from "react";
import DataComponent from "./../../Shared/index";

function SigningBasket() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/Signing-basket.md"
      withBoth={true}
      linkPrevious="recurring-payments"
      textPrevious="Recurring payments"
      linkNext="payouts"
      textNext="Payouts"
    />
  );
}

export default SigningBasket;
