import React from "react";
import DataComponent from "./../../Shared/index";

function FlowsConfiguration() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/flows-configuration.md"
      withBoth={true}
      linkPrevious="getting-an-access-token"
      textPrevious="Getting an access token"
      linkNext="integration"
      textNext="Integration"
    />
  );
}

export default FlowsConfiguration;
