import React from "react";
import DataComponent from "./../../Shared/index";

function Integration() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/integration.md"
      withBoth={true}
      linkPrevious="flows-configuration"
      textPrevious="Configuration"
      linkNext="webhooks"
      textNext="Webhooks"
    />
  );
}

export default Integration;
