import React from "react";
import DataComponent from "./../../Shared/index";

function Webhooks() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/webhooks.md"
      withBoth={true}
      linkPrevious="integration"
      textPrevious="Integration"
      linkNext="authorization"
      textNext="Authorization"
    />
  );
}

export default Webhooks;
