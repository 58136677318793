import React from "react";
import DataComponent from "./../../Shared/index";

function InsightsCategories() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/Insights-categories.md"
      withBoth={true}
      linkPrevious="use-cases"
      textPrevious="Use cases"
      linkNext="payment-statuses"
      textNext="Payment statuses"
    />
  );
}

export default InsightsCategories;
