import React, { useState, useEffect } from "react";
import Footer from "./../Components/Footer/index";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import axios from "axios";
import "../Assets/scss/globals/_global-md.scss";

const gfm = require("remark-gfm");
const DataComponent = ({
  url,
  linkPrevious,
  textPrevious,
  linkNext,
  textNext,
  withBoth,
  classname,
  link,
  navigation,
  text,
}) => {
  const [post, setPost] = useState("");

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        setPost(response.data);
      })
      .catch((err) => console.log(err));
  });

  return (
    <article id="content">
      <div className="container">
        <ReactMarkdown
          skipHtml={true}
          remarkPlugins={[gfm]}
          rehypePlugins={[rehypeRaw]}
          children={post}
        />
      </div>

      <div className="pt-5 pb-3">
        {withBoth === true ? (
          <Footer
            withBoth={withBoth}
            classnamePrevious="text-left"
            linkPrevious={linkPrevious}
            navigationPrevious=" &lt; PREVIOUS"
            textPrevious={textPrevious}
            classnameNext="text-right"
            linkNext={linkNext}
            navigationNext="NEXT &gt;"
            textNext={textNext}
          />
        ) : (
          <Footer
            withBoth={withBoth}
            classname={classname}
            link={link}
            navigation={navigation}
            text={text}
          />
        )}
      </div>
    </article>
  );
};

export default DataComponent;
