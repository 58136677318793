import React from "react";
import DataComponent from "./../../Shared/index";

function SplitPaymentsBetweenMerchantAndPartner() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/Split-Payments-Between-Merchant-and-Partner.md"
      withBoth={true}
      linkPrevious="merchants"
      textPrevious="Merchants"
      linkNext="sandbox"
      textNext="Sandbox"
    />
  );
}

export default SplitPaymentsBetweenMerchantAndPartner;
