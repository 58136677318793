import React from "react";
import DataComponent from "./../../Shared/index";
function AccountVerification() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/Account-verification.md"
      withBoth={true}
      linkPrevious="authorization"
      textPrevious="Authorization"
      linkNext="payment"
      textNext="Payment"
    />
  );
}
export default AccountVerification;