import React from "react";
import DataComponent from "./../../Shared/index";

function GettingAnAccessToken() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/access-token.md"
      withBoth={true}
      linkPrevious="create-an-app"
      textPrevious="Creating an app"
      linkNext="flows-configuration"
      textNext="Flows configuration"
    />
  );
}

export default GettingAnAccessToken;
