import React from "react";
import DataComponent from "./../../Shared/index";

function CreateAnApp() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/create-an-app.md"
      withBoth={true}
      linkPrevious="getting-started"
      textPrevious="Getting started"
      linkNext="getting-an-access-token"
      textNext="Getting an access token"
    />
  );
}

export default CreateAnApp;
