import React from "react";
import { Link } from "react-router-dom";

export default ({
  withBoth,
  classname,
  link,
  navigation,
  text,
  classnamePrevious,
  linkPrevious,
  navigationPrevious,
  textPrevious,
  classnameNext,
  linkNext,
  navigationNext,
  textNext,
}) =>
  withBoth === true ? (
    <footer>
      <div className="container footer footer__navigation">
        <div className={classnamePrevious}>
          <Link to={linkPrevious} className="footer__navigation--link">
            {navigationPrevious}{" "}
            <h6 className="m-0 mb-2 p-0">
              <b>{textPrevious}</b>
            </h6>
          </Link>
        </div>
        <div className={classnameNext}>
          <Link to={linkNext} className="footer__navigation--link">
            {navigationNext}{" "}
            <h6 className="m-0 mb-2 p-0">
              <b>{textNext}</b>
            </h6>
          </Link>
        </div>
      </div>
    </footer>
  ) : (
      <footer>
        <div className="container footer">
          <div className={classname}>
            <Link to={link} className="footer__navigation--link">
              {navigation}{" "}
              <h6 className="m-0 mb-2 p-0">
                <b>{text}</b>
              </h6>
            </Link>
          </div>
        </div>
      </footer>
    );
