import React from "react";
import DataComponent from "./../../Shared/index";

function Glossary() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/glossary.md"
      withBoth={true}
      linkPrevious="supported-providers"
      textPrevious="Supported providers"
      linkNext="faqs"
      textNext="FAQs"
    />
  );
}

export default Glossary;
