import React from "react";
import DataComponent from "./../../Shared/index";

function SimplifiedPayments() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/Simplified-payments.md"
      withBoth={true}
      linkPrevious="payment"
      textPrevious="Payment"
      linkNext="international-payments"
      textNext="International payments"
    />
  );
}

export default SimplifiedPayments;
