import React from "react";
import DataComponent from "./../../Shared/index";

function InternationalPayments() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/International-payments.md"
      withBoth={true}
      linkPrevious="simplified-payments"
      textPrevious="Simplified payments"
      linkNext="recurring-payments"
      textNext="Recurring payments"
    />
  );
}

export default InternationalPayments;
