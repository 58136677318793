import React from "react";
import DataComponent from "./../../Shared/index";

function UseCases() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/use-cases.md"
      withBoth={true}
      linkPrevious="payouts"
      textPrevious="Payouts"
      linkNext="insights-categories"
      textNext="Insights categories"
    />
  );
}

export default UseCases;
