import React from "react";
import DataComponent from "./../../Shared/index";

function Payment() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/flows-payment.md"
      withBoth={true}
      linkPrevious="account-verification"
      textPrevious="Account verification"
      linkNext="simplified-payments"
      textNext="Simplified payments"
    />
  );
}

export default Payment;
