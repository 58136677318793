import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  AiFillHome,
  AiFillAccountBook,
  AiOutlineAppstoreAdd,
  AiFillBank,
  AiFillLock,
  AiFillTool,
} from "react-icons/ai";
import { FaBookOpen, FaProjectDiagram, FaQuestion } from "react-icons/fa";
import { RiDashboardFill } from "react-icons/ri";
import { TiPointOfInterest } from "react-icons/ti";
import { SiCodesandbox } from "react-icons/si";
import { MdPayment, MdInsights } from "react-icons/md";
import { CgTemplate } from "react-icons/cg";
import { BsShieldLockFill } from "react-icons/bs";
import { GiPuzzle } from "react-icons/gi";
import { SiWebflow } from "react-icons/si";

const Sidebar = () => {
  const toggleSidebar = () => {
    if (window.innerWidth < 600) {
      document.getElementById("sidebar").classList.toggle("collapsed");
      document
        .getElementById("sidebar-toggle")
        .classList.toggle("toggle-collapsed");
    }
  };

  return (
    <aside id="sidebar" className="sidebar sidebar-scrollbar">
      <nav>
        <div className="sidebar__header">
          <Link to="/">
            <img
              src="https://cdn.finshark.io/logo/logo-white.svg"
              width={168}
              height={41}
              alt="Finshark-logo"
            />
          </Link>
        </div>
        <hr className="sidebar__break" />

        <div>
          <h5 className="sidebar__title">INTRODUCTION</h5>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <AiFillHome size="24" />
              <span className="mt-1 pl-3">Getting started</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/create-an-app"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <AiOutlineAppstoreAdd size="24" />
              <span className="mt-1 pl-3">Creating an app</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/getting-an-access-token"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <AiFillLock size="22" />
              <span className="mt-1 pl-3">Getting an access token</span>
            </NavLink>
          </div>
        </div>
        <hr className="sidebar__break" />

        <div>
          <h5 className="sidebar__title">FLOWS</h5>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/flows-configuration"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <AiFillTool size="24" />
              <span className="mt-1 pl-3"> Configuration</span>
            </NavLink>
          </div>

          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/integration"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <GiPuzzle size="24" />
              <span className="mt-1 pl-3">Integration</span>
            </NavLink>
          </div>

          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/webhooks"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <SiWebflow size="24" />
              <span className="mt-1 pl-3">Webhooks</span>
            </NavLink>
          </div>

          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/authorization"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <BsShieldLockFill size="24" />
              <span className="mt-1 pl-3">Authorization</span>
            </NavLink>
          </div>

          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/account-verification"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <AiFillAccountBook size="24" />
              <span className="mt-1 pl-3"> Account verification</span>
            </NavLink>
          </div>

          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/payment"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              {" "}
              <MdPayment size="24" />
              <span className="mt-1 pl-3">Payment</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/simplified-payments"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              {" "}
              <MdPayment size="24" />
              <span className="mt-1 pl-3">Simplified payments</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/international-payments"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              {" "}
              <MdPayment size="24" />
              <span className="mt-1 pl-3">International payments</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/recurring-payments"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              {" "}
              <MdPayment size="24" />
              <span className="mt-1 pl-3">Recurring payments</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/payment-with-template"
              className="sidebar__item--link--disabled"
            >
              {" "}
              <CgTemplate size="24" />
              <span className="mt-1 pl-3">Payment with template</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/signing-basket"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <MdPayment size="24" />
              <span className="mt-1 pl-3">Signing basket</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/payouts"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <MdPayment size="24" />
              <span className="mt-1 pl-3">Payouts</span>
            </NavLink>
          </div>
        </div>

        <hr className="sidebar__break" />
        <div>
          <h5 className="sidebar__title">USEFUL INFO</h5>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/use-cases"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <FaProjectDiagram size="24" />
              <span className="mt-1 pl-3">Use cases</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/insights-categories"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <MdInsights size="24" />
              <span className="mt-1 pl-3">Insights categories</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/payment-statuses"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <MdPayment size="24" />
              <span className="mt-1 pl-3">Payment statuses</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/merchants"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <MdPayment size="24" />
              <span className="mt-1 pl-3">Merchants</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/split-payments"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <MdPayment size="24" />
              <span className="mt-1 pl-3">Split payments</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/sandbox"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <SiCodesandbox size="24" />
              <span className="mt-1 pl-3">Sandbox</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/supported-providers"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <AiFillBank size="24" />
              <span className="mt-1 pl-3">Supported providers</span>
            </NavLink>
          </div>

          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/glossary"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <FaBookOpen size="24" />
              <span className="mt-1 pl-3">Glossary</span>
            </NavLink>
          </div>
          <div className="mt-2  sidebar__item">
            <NavLink
              exact
              to="/faqs"
              className="sidebar__item--link"
              activeClassName="sidebar__item--link-active"
              onClick={toggleSidebar}
            >
              <FaQuestion size="24" />
              <span className="mt-1 pl-3">FAQs</span>
            </NavLink>
          </div>
        </div>

        <hr className="sidebar__break" />
        <div>
          <h5 className="sidebar__title">LINKS</h5>
          <div className="mt-2 sidebar__item ">
            <a
              target="_blank"
              href="https://oas.finshark.io"
              rel="noopener noreferrer"
              className="sidebar__item--link-visited"
            >
              {" "}
              <TiPointOfInterest size="24" />
              <span className="mt-1 pl-3"> API Reference</span>
            </a>
          </div>

          <div className="mt-2  sidebar__item">
            <a
              target="_blank"
              href="https://portal.finshark.io/"
              rel="noopener noreferrer"
              className="sidebar__item--link-visited"
            >
              {" "}
              <RiDashboardFill size="24" />
              <span className="mt-1 pl-3"> Portal</span>
            </a>
          </div>
        </div>
      </nav>
    </aside>
  );
};
export default Sidebar;
