import React from "react";
import DataComponent from "./../../Shared/index";

function RecurringPayments() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/Recurring-payments.md"
      withBoth={true}
      linkPrevious="international-payments"
      textPrevious="International payments"
      linkNext="signing-basket"
      textNext="Signing basket"
    />
  );
}

export default RecurringPayments;
