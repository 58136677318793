import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import SideBar from "../Components/Sidebar";
// import Home from "../Components/CreatingYourFirstApp";
import AccountVerification from "../Components/AccountVerification";
import Authorization from "../Components/Authorization";
import CreateAnApp from "../Components/CreateAnApp";
import Faqs from "../Components/Faqs";
import FlowsConfiguration from "../Components/FlowsConfiguration";
import GettingAnAccessToken from "../Components/GettingAnAccessToken";
import GettingStarted from "../Components/GettingStarted";
import Glossary from "../Components/Glossary";
import InsightsCategories from "../Components/InsightsCategories";
import Integration from "../Components/Integration";
import InternationalPayments from "../Components/InternationalPayments";
import Payment from "../Components/Payment";
import PaymentStatuses from "../Components/PaymentStatuses";
import PaymentWithTemplate from "../Components/PaymentWithTemplate";
import RecurringPayments from "../Components/RecurringPayments";
import Sandbox from "../Components/Sandbox";
import SidebarToggle from "../Components/SidebarToggle";
import SigningBasket from "../Components/SigningBasket";
import SimplifiedPayments from "../Components/SimplifiedPayments";
import SupportedProviders from "../Components/SupportedProviders";
import UseCases from "../Components/UseCases";
import Webhooks from "../Components/Webhooks";
import ScrollPositionRestoration from "../Utilities/ScrollPositionRestoration";
import Payouts from "../Components/Payouts";
import SplitPaymentsBetweenMerchantAndPartner from "../Components/SplitPaymentsBetweenMerchantAndPartner";
import Merchants from "../Components/Merchants";

function Routes() {
  return (
    <BrowserRouter>
      <SideBar />
      <SidebarToggle />
      <ScrollPositionRestoration>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <GettingStarted {...props} />}
          />

          <Route
            exact
            path="/use-cases"
            render={(props) => <UseCases {...props} />}
          />
          <Route
            path="/account-verification"
            render={(props) => <AccountVerification {...props} />}
          />
          <Route
            path="/create-an-app"
            render={(props) => <CreateAnApp {...props} />}
          />
          <Route
            path="/getting-an-access-token"
            render={(props) => <GettingAnAccessToken {...props} />}
          />
          <Route
            path="/flows-configuration"
            render={(props) => <FlowsConfiguration {...props} />}
          />
          <Route
            path="/authorization"
            render={(props) => <Authorization {...props} />}
          />
          <Route path="/payment" render={(props) => <Payment {...props} />} />
          <Route
            path="/simplified-payments"
            render={(props) => <SimplifiedPayments {...props} />}
          />
          <Route
            path="/payment-statuses"
            render={(props) => <PaymentStatuses {...props} />}
          />

          <Route
            path="/payment-with-template"
            render={(props) => <PaymentWithTemplate {...props} />}
          />
          <Route
            path="/signing-basket"
            render={(props) => <SigningBasket {...props} />}
          />
          <Route path="/payouts" render={(props) => <Payouts {...props} />} />
          <Route
            path="/merchants"
            render={(props) => <Merchants {...props} />}
          />
          <Route
            path="/split-payments"
            render={(props) => (
              <SplitPaymentsBetweenMerchantAndPartner {...props} />
            )}
          />
          <Route path="/sandbox" render={(props) => <Sandbox {...props} />} />
          <Route
            path="/international-payments"
            render={(props) => <InternationalPayments {...props} />}
          />
          <Route
            path="/recurring-payments"
            render={(props) => <RecurringPayments {...props} />}
          />
          <Route
            path="/supported-providers"
            render={(props) => <SupportedProviders {...props} />}
          />
          <Route
            path="/insights-categories"
            render={(props) => <InsightsCategories {...props} />}
          />
          <Route path="/glossary" render={(props) => <Glossary {...props} />} />
          <Route
            path="/integration"
            render={(props) => <Integration {...props} />}
          />
          <Route path="/webhooks" render={(props) => <Webhooks {...props} />} />
          <Route path="/faqs" render={(props) => <Faqs {...props} />} />

          <Redirect to="/" />
        </Switch>
      </ScrollPositionRestoration>
    </BrowserRouter>
  );
}

export default Routes;
