import React from "react";
import DataComponent from "./../../Shared/index";

function GettingStarted() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/getting-started.md"
      withBoth={false}
      classname="text-right"
      link="create-an-app"
      navigation="NEXT &gt;"
      text="Creating an app"
    />
  );
}

export default GettingStarted;
