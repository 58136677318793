import React from "react";
import DataComponent from "./../../Shared/index";

function Faqs() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/FAQ.md"
      withBoth={false}
      classname="text-left"
      link="glossary"
      navigation="&lt; PREVIOUS"
      text="Glossary"
    />
  );
}

export default Faqs;
