import React from "react";
import Footer from "../Footer";
// import ReactMarkdown from "react-markdown";
// import "../../content/test.css";
// import rehypeRaw from "rehype-raw";
// const gfm = require("remark-gfm");

function PaymentWithTemplate() {
  //   const file_name = "test.md";
  //   const [post, setPost] = useState("");

  //   useEffect(() => {
  //     import(`../../content/${file_name}`)
  //       .then((res) => {
  //         fetch(res.default)
  //           .then((res) => res.text())
  //           .then((res) => setPost(res));
  //       })
  //       .catch((err) => console.log(err));
  //   });

  return (
    <article id="content">
      <div className="container">
        {/* <ReactMarkdown
          skipHtml="true"
          remarkPlugins={[gfm]}
          rehypePlugins={[rehypeRaw]}
          children={post}
        /> */}
      </div>
      <div className="pt-5 pb-3">
        <Footer
          withBoth={true}
          classnamePrevious="text-left"
          linkPrevious="payment"
          navigationPrevious=" &lt; PREVIOUS"
          textPrevious="Payment"
          classnameNext="text-right"
          linkNext="sandbox"
          navigationNext="NEXT &gt;"
          textNext="Sandbox"
        />
      </div>
    </article>
  );
}

export default PaymentWithTemplate;
