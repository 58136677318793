import React from "react";
import DataComponent from "./../../Shared/index";

function Sandbox() {
  return (
    <DataComponent
      url="https://cdn.finshark.io/docs/sandbox.md"
      withBoth={true}
      linkPrevious="split-payments"
      textPrevious="Split payments"
      linkNext="supported-providers"
      textNext="Supported providers"
    />
  );
}

export default Sandbox;
